.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container {
  background-color: #fff;
  max-width: 600px;
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 2rem; */
  border-radius: 20px;
  position: relative;
}

/* form */
.contact_form {
  border-radius: 20px;
  padding: 40px;
  background: #f9f9f9;
}

.contact_form h1 {
  font-weight: bold;
  font-size: 19px;
  margin-bottom: 20px;
}

.inputName {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.inputName label,
.inputName span,
.inputNumber {
  font-size: 14px;
  color: #777;
  margin-bottom: 5px;
}

.inputNumber {
  color: #666;
  display: flex;
  margin-bottom: 20px;
  width: 92%;
}

.inputName input,
.inputNumber input,
.inputName textarea {
  outline: none !important;
  border: none;
  border: 1px solid #ddd;
  width: 85%;
  padding: 4px;
}

.inputName textarea {
  height: 60px;
}

.inputName textarea::placeholder {
  font-size: 10px;
  padding: 4px;
  letter-spacing: 1px;
}

.inputFields {
  margin-top: 10px;
}

.buttons {
  display: flex;
}

.buttons button {
  display: block;
  outline: none;
  border: none;
  background-color: #0e8a44;
  border-radius: 2px;
  padding: 6px 22px;
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  margin: 10px 0px 0px 0px;
  letter-spacing: 0.5px;
}

.buttons button:last-child {
  margin-left: 20px;
  background: #bd1b1b;
}

@media screen and (max-width: 768px) {
  .contact_form {
    padding: 40px 25px;
  }

  .inputName input,
  .inputNumber input,
  .inputName textarea {
    width: 100%;
  }

  .inputNumber {
    width: 100%;
  }

  .inputNameContainer:last-child {
    margin-left: 30px;
  }
}
