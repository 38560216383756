.navbar {
  padding: 20px 40px;
  background-color: #0e1913;
  /* background-color: #0e1913e3 !important; */
  transition: 0.3s;
}

.navWithoutBackground {
  /* background-color: transparent !important; */
}

.navBackground {
  background-color: #0e1913 !important;
}

.header {
  margin-bottom: 113px !important;
  /* margin-bottom: 67px !important; */
}

.logo {
  width: 251px;
}

.navbarCollapse {
  justify-content: flex-end;
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.nav a {
  margin: 0px 18px;
  color: #fff !important;
  text-decoration: none;
  font-weight: 700;
  letter-spacing: 0.3px;
  font-size: 17px;
}

.nav a:hover {
  color: #ddd !important;
}

.navbar-nav .dropdown-menu {
  padding: 0px !important;
  background-color: #bdbdbd61;
  margin-top: 5px;
}

.navDropItem {
  margin: 0px !important;
  padding: 7px 10px !important;
  width: 170px;
}

.navDropItem:hover {
  background-color: #3bca81;
}

.contactBtn {
  padding: 5px 25px !important;
  border-radius: 5px;
  border: 2px solid #3bca81;
}

@media screen and (max-width: 768px) {
  .navbar {
    padding: 20px 10px;
  }

  .navbarToggle {
    border: none;
  }

  .navbarToggle:focus {
    box-shadow: none !important;
  }

  .nav a:first-child {
    margin-top: 50px !important;
  }

  .nav a {
    margin: 0px !important;
    margin-bottom: 32px !important;
    text-align: center;
    padding: 10px;
    border: 1px solid #ddd;
    width: 100%;
  }

  .contactBtn {
    width: 40% !important;
    background-color: #3bca81;
  }

  .headerContent h1,
  .headerContent h2 {
    font-size: 29px;
  }
  .buttons {
    bottom: 50px;
    width: 100%;
  }
  .buttons button {
    font-size: 13px;
  }

  .buttons button:first-child {
    margin-right: 0px;
  }

  .logo {
    width: 200px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .buttons {
    justify-content: center;
  }

  .buttons button {
    margin: 0px 30px;
    font-size: 25px;
  }
}

/* navBackground */
.navBackground.navbar {
  padding: 15px 40px;
  background-color: #0e1913e3 !important;
  transition: 0.3s;
  padding: 6px 25px !important;
  box-shadow: 0px -4px 25px 1px #303030;
}

@media (max-width: 769px) {
  .header {
    margin-bottom: 100px !important;
  }
}
