.projects {
  padding: 40px 0px;
  background-color: #f9fafc;
}

.projects h1 {
  font-size: 30px;
  text-align: center;
}

.projects_border {
  width: 80px;
  height: 3px;
  margin: auto;
  background-color: #777;
}

.sliderProjects {
  width: 91%;
  margin: auto;
  margin-top: 30px;
  text-align: center;
}

.sliderProjects img {
  width: 100%;
  height: 380px;
  object-fit: cover;
}

.sliderProjects h3 {
  font-size: 15px;
  margin: 10px 0px 0px 0px;
}

.slick-next,
.slick-prev {
  display: none !important;
}
