.footer {
  background-color: #0e1913;
  padding: 20px 40px;
  color: #fff;
}

.footerContacts {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footerContactsImg {
  height: 55px;
}

.footerContacts ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-content: center;
}

.footerContacts ul li {
  text-align: center;
  margin-right: 40px;
}

.footerContacts ul li a {
  text-decoration: none;
  color: #fff !important;
  transition: 0.2s;
}

.footerContacts ul li:last-child {
  text-decoration: underline;
  margin-right: 0px;
}

.footerContacts ul li:hover a {
  color: #3bca81 !important;
}

.contactUsLink {
  color: #fff !important;
  text-decoration: none;
}

.social ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.social ul li {
  margin: 0px 15px;
  margin-bottom: 30px;
}

.social ul li img {
  width: 20px;
}

.social a {
  display: block;
  text-decoration: none;
  color: #fff;
  text-align: center;
}

.social {
  display: flex;
  align-items: center;
}

.footer .social p {
  margin: 0;
  /* text-align: center; */
  /* display: flex; */
  /* align-items: center; */
  font-size: 17px;
  color: rgb(233, 233, 233);
}

.footer .social img {
  height: 20px;
  margin-left: 5px;
  margin-top: -9.5px;
}

@media screen and (max-width: 768px) {
  .footer {
    padding: 40px 10px;
  }

  .footerContacts {
    flex-direction: column;
    align-items: center;
    /* margin-bottom: 18px; */
  }

  .footerContacts img {
    width: 180px;
    height: auto;
    margin-bottom: 20px;
  }

  .social {
    margin: 30px 0px;
    display: flex;
    flex-direction: column;
  }

  .social img {
    /* margin: 0; */
    margin-top: 10px;
  }

  .footerContacts ul li a {
    font-size: 20px;
  }
  .social p {
    width: 100%;
    font-size: 20px !important;
    text-align: center;
    margin-bottom: 20px !important;
  }
}
