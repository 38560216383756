/* ***********************contact************************ */
.contact {
  padding: 50px 30px;
}

/* ***********************media************************ */
.media h1 {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 35px;
  margin-bottom: 20px;
}

/* ***********************contactUs************************ */
.contactUs p {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  align-content: center;
  font-weight: 300;
}

.contactUs p a {
  text-decoration: none;
  color: #000;
}

.contactUs .icons {
  margin-right: 7px;
  font-size: 20px;
}

/* ***********************form************************ */
.form label {
  font-weight: 400;
  margin-bottom: 5px;
}

.namePhoneContainer {
  display: flex;
  width: 100% !important;
}

.name,
.phone {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-bottom: 15px;
}

.name {
  margin-right: 20px;
}

.name input,
.phone input {
  width: 100%;
}

.email,
.message {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.name input,
.phone input,
.email input,
.message textarea {
  padding: 4px 10px;
  outline: none;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.message textarea {
  resize: none;
  height: 120px;
}

.requestBtn {
  width: 100%;
  background-color: #3bca81;
  border: 1px solid #3bca81;
  font-weight: 400;
  font-style: italic;
  outline: none !important;
  padding: 8px 0px;
  border-radius: 7px;
  color: #fff;
  transition: 0.3s;
}

.requestBtn:hover {
  background: none;
  color: #3bca81;
}

.requestBtn:focus {
  outline: none !important;
}

@media screen and (max-width: 768px) {
  .contact {
    padding: 50px 0px;
  }
}
