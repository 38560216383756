.serviceSlideContainer {
  padding: 80px 30px;
  border-bottom: 1px solid #f1f1f1;
}

/* **********slideContent*********** */
.slideContent h1 {
  font-weight: bold;
  color: #333;
  margin-bottom: 30px;
}

.slideContent p {
  font-weight: 500;
  color: #555;
}

/* **********imgSlideContent*********** */
.imgSlideContent {
  margin-bottom: 20px;
}

.imgSlideContent img {
  width: 100%;
}

/* slick-dots */
.slick-dots li button::before {
  font-size: 10px;
  display: none;
}
