.values {
  padding: 80px 0px;
}

.values h1 {
  font-size: 35px;
  text-align: center;
  font-weight: 400;
  text-transform: uppercase;
  color: #333;
}

.values .values_conent {
  margin-top: 60px;
}

.col {
  text-align: center;
}

.containerContent {
  border: 1px solid #ddd;
  background: #f9fafc;
  padding: 40px 20px;
  transition: 0.5s;
  border-radius: 8px;
}

.containerContent:hover {
  transform: scale(1.01);
  box-shadow: 0px 0px 10px 0px rgb(220 220 220);
  background: none;
}

.containerContent img {
  height: 70px;
  margin-bottom: 30px;
  transition: 0.5s;
}

.containerContent:hover img {
  transform: rotate(360deg);
}

.containerContent p {
  font-weight: bold;
  font-size: 19px;
  margin-bottom: 0px;
  color: #444;
}

.containerContent span {
  letter-spacing: 0.3px;
}
