/* .row {
  padding-left: 0 !important;
  padding-right: 0 !important;
} */

.projectPageContainer {
  padding-left: 0;
  padding-right: 0;
}

.projectPage {
  background-color: #0e1913;
  color: #fff;
}

.project_conent {
  padding: 50px 30px;
  padding-top: 80px;
  background-color: #fff;
}

.project_conent .hotelsTitle,
.project_conent .resortsTitle {
  color: #66e19f;
  display: block;
  font-weight: bold;
  font-size: 35px;
  text-transform: uppercase;
}

.project_conent h1,
.project_vision h1 {
  color: #0c7238;
  font-weight: bold;
  letter-spacing: -1px;
  margin-bottom: 20px;
}

.project_conent .col .ul,
.project_content_1 .col1 .ul {
  list-style: none; /* Remove default bullets */
  padding: 0px;
  margin: 0px;
  margin-top: 30px;
}

.project_conent .col .ul li,
.project_content_1 .col1 .ul li {
  display: flex;
  align-items: center;
  font-size: 20px;
  margin-bottom: 20px;
  color: rgb(61, 61, 61);
  font-weight: 400;
}

/* project_content_1 */
.row {
  margin-right: 0 !important;
  margin-left: 0 !important;
  /* height: 400px !important; */
}

.project_content_1 .col1 {
  /* background-color: #f8fafb; */
  /* height: 100px !important; */
  /* background-color: red; */
}

.project_content_1 .col {
  padding-left: 0 !important;
  padding-right: 0 !important;
  height: inherit;
}

.project_content_1 .col img {
  width: 100%;
  /* height: 100%; */
  height: 500px !important;
  /* object-fit: cover; */
  cursor: pointer;
}

.project_content_1 .hotelsTitle {
  color: #0c7238;
  font-size: 28px !important;
  font-weight: bold;
  letter-spacing: 1px;
}

.project_content_1 .col1 {
  color: #000;
  padding: 20px !important;
}

.project_content_1 .col1 .ul {
  margin-top: 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* align-items: center; */
}

.project_content_1 .col1 .ul li {
  margin-bottom: 20px;
  font-size: 25px;
  font-weight: bold;
  color: #66e19f;
  display: flex;
  align-items: center;
}

.project_content_1 .col1 .ul li span {
  margin-left: 10px;
  letter-spacing: 0.2px;
}

@media screen and (max-width: 768px) {
  .project_conent_col_hidden {
    display: none;
  }

  .project_conent {
    padding: 50px 10px;
  }

  .project_conent .col .ul li,
  .project_content_1 .col1 .ul li {
    font-size: 13px;
    margin-bottom: 20px;
  }
  .project_content_1 .col1 {
    height: auto;
  }

  .row {
    height: auto !important;
  }

  .project_content_1 .row .col {
    height: inherit;
  }

  .project_content_1 .col img {
    height: auto !important;
    object-fit: cover;
  }
}
