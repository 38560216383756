.services {
  padding: 80px 0px;
  background-color: #f9fafc;
}

.services h1 {
  font-size: 35px;
  text-align: center;
  font-weight: 400;
  text-transform: uppercase;
  color: #333;
}

.serP {
  text-align: center;
  font-size: 19px;
  font-weight: bold;
  color: rgb(86, 86, 86);
  width: 38%;
  margin: auto;
  margin-top: 15px;
  font-style: italic;
  letter-spacing: 0.5px;
}

.services .services_conent {
  margin-top: 60px;
}

.row {
  justify-content: center !important;
}

.col {
  /* padding: 0px 40px; */
  margin-right: 25px;
}

.itemsShadow {
  box-shadow: 0px 0px 10px 0px #ddd;
}

.items {
  background: #fff;
  border-radius: 5px;
  margin: 0px 0px;
  padding: 13px 16px;
  padding: 20px;
  height: 270px;
}

.services img {
  width: 35px;
  height: 35px;
  margin-bottom: 10px;
  background-color: #f6f6f6;
  box-shadow: 0px 0px 3px 0px #0e8a44;
  padding: 4px;
  border-radius: 5px;
  display: block;
}

.services_conent p {
  font-weight: bold;
  font-size: 17px;
  margin: 5px 0px 25px;
}

.services_ul {
  list-style: none;
  padding: 0px;
  margin: -9px;
}

.services_ul li {
  font-weight: bold;
  font-size: 13px;
  margin-bottom: 5px;
  color: #333;
}

.services_ul li .icon {
  color: #000 !important;
  font-size: 25px;
}
