.values {
  padding: 40px 0px;
}

.values h1 {
  font-size: 30px;
  text-align: center;
}

.values_border {
  width: 80px;
  height: 3px;
  margin: auto;
  background-color: #777;
}

.slider {
  width: 91%;
  margin: auto;
  margin-top: 30px;
  text-align: center;
}

.slider img {
  height: 170px;
  object-fit: cover;
  margin: auto;
  margin-bottom: 20px;
}

.slider p {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 0px;
}

.slick-next,
.slick-prev {
  display: none !important;
}
