.projects {
  background-color: #f9fafc;
  padding: 80px 0px;
}

.projects h1 {
  font-size: 35px;
  text-align: center;
  font-weight: 400;
  text-transform: uppercase;
  color: #333;
}

.projects p {
  width: 60%;
  margin: 25px auto;
  text-align: center;
  line-height: 27px;
  color: #666;
}

.projects_border {
  width: 80px;
  height: 3px;
  margin: auto;
  background-color: #777;
}

.projects .project_conent {
  margin-top: 60px;
  overflow: hidden;
  box-shadow: 0px 0px 20px 0px rgb(220 220 220);
  border-radius: 10px;

  position: relative;
  transform: translateY(100px);
  opacity: 0;
  transition: 1.5s;
}

.serDiv_BackGround {
  transform: translateY(0px) !important;
  opacity: 1 !important;
}

.projects .col {
  padding-left: 0px;
  padding-right: 0px;
}

.containerImage {
  overflow: hidden;
}

.projects img {
  width: 100%;
  cursor: pointer;
  transition: 0.5s;
  height: 200px;
  object-fit: cover;
}

.containerImage img:hover {
  transform: scale(1.1);
}

.projects span {
  color: #333;
  display: block;
  margin: 9px 0px;
  text-align: center;
  font-weight: 400;
  font-size: 20px;
}
