.values {
  padding: 80px 0px;
  overflow: hidden;
}

.values h1 {
  font-size: 35px;
  text-align: center;
  font-weight: 400;
  text-transform: uppercase;
  color: #333;
}

.serP {
  text-align: center;
  font-size: 23px;
  width: 48%;
  margin: auto;
  margin-top: 15px;
  font-style: italic;
  font-weight: 300;
  letter-spacing: 0.5px;
  position: relative;
  transform: translateY(100px);
  opacity: 0;
  transition: 2s;
}

.serP:last-of-type {
  /* font-weight: bold; */
  color: #444;
}

.serp_BackGround {
  transform: translateY(0px);
  opacity: 1;
}

.values_conent {
  position: relative;
  transform: translatex(100px);
  opacity: 0;
  transition: 2s;
}

.serDiv_BackGround {
  transform: translatex(0px);
  opacity: 1;
}

.serP span {
  font-weight: bold !important;
}

.values .values_conent {
  margin-top: 60px;
}

.values .col {
  text-align: center;
}

.containerContent {
  border: 1px solid #ddd;
  background: #f9fafc;
  padding: 40px 20px;
  transition: 0.5s;
  border-radius: 8px;
  height: 180px;
}

.containerContent:hover {
  transform: scale(1.01);
  box-shadow: 0px 0px 10px 0px rgb(220 220 220);
  background: none;
}

.containerContent img {
  height: 70px;
  margin-bottom: 30px;
  transition: 0.5s;
}

.containerContent:hover img {
  transform: rotate(360deg);
}

.containerContent p {
  font-weight: 400;
  font-size: 19px;
  margin-bottom: 0px;
  color: #444;
}

.containerContent span {
  letter-spacing: 0.3px;
}

@media screen and (max-width: 768px) {
  .serP {
    width: 90%;
  }

  .containerContent {
    margin-bottom: 20px;
  }
}
