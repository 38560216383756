.servicesMobile {
  /* padding: 40px 0px; */
  /* padding-bottom: 60px; */
  /* height: 100vh; */
  background-color: #f9fafc;
}

.servicesMobile h1 {
  font-size: 30px;
  text-align: center;
  padding-top: 20px;
}

.servicesMobile_border {
  width: 80px;
  height: 3px;
  margin: auto;
  background-color: #777;
}

.servicesMobile .slider {
  background-color: #fff;
  width: 91%;
  margin-top: 20px;
  text-align: left;
}

.servicesMobile_content {
  /* padding-top: 40px; */
  /* padding-left: 15px; */
  padding: 25px;
}

.servicesMobile .slider img {
  margin: inherit;
  width: 35px;
  height: 35px;
  margin-bottom: 10px;
  background-color: #f6f6f6;
  box-shadow: 0px 0px 3px 0px #0e8a44;
  padding: 4px;
  border-radius: 5px;
}

.servicesMobile .slider p {
  font-weight: bold;
  font-size: 17px;
  margin: 19px 0px 15px;
}

.servicesMobile .slick-next,
.servicesMobile .slick-prev {
  display: none !important;
}

.services_ul {
  list-style: none;
  padding: 0;
  margin: -6px;
}

.services_ul li {
  font-size: 14px;
  color: #555;
  margin-bottom: 7px;
  font-weight: bold;
}

.services_ul li .icon {
  color: #000 !important;
  font-size: 25px;
}
